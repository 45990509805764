import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    justifyContent: "center",
  },
  chip: {
    margin: theme.spacing.unit,
  },
});

class SimpleTabs extends React.Component {
  state = {
    value: 0,
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;

    return (
      <div className={classes.root}>
        <AppBar position="static" style={{justifyContent:"center"}}>
          <Tabs value={value} onChange={this.handleChange}>
            <Tab label="Who we are?" />
            <Tab label="Contacts" />
            <Tab label="We are using" />
          </Tabs>
        </AppBar>
        {value === 0 && <TabContainer>
            <div className="info">
            We are a team of developers and our goal is developing 
cutting-edge SPAs applications with a focus on mobile devices.
            </div></TabContainer>}
        {value === 1 && <TabContainer>
            <div className="contacts">
            Enix Technologies s.r.o. <br />
            Martinická 987/3 <br />
            197 00 Praha 9 <br />
            Czech Republic <br />
            IČO: 28307704 <br />
            DIČ: CZ28307704 <br />
            <br />
            Bc. Filip Kroča<br />
            ceo@enix-technologies.eu

            </div>
        </TabContainer>}
        {value === 2 && <TabContainer>
            <div className="info">
            <Chip
                icon={<DoneIcon />}
                label="ReactJs"
                clickable
                className={classes.chip}
                color="primary"
                deleteIcon={<DoneIcon />}
            />
             <Chip
                icon={<DoneIcon />}
                label="Apollo"
                clickable
                className={classes.chip}
                color="primary"
                deleteIcon={<DoneIcon />}
            />
             <Chip
                icon={<DoneIcon />}
                label="Apollo Link"
                clickable
                className={classes.chip}
                color="primary"
                deleteIcon={<DoneIcon />}
            />
             <Chip
                icon={<DoneIcon />}
                label="SASS"
                clickable
                className={classes.chip}
                color="primary"
                deleteIcon={<DoneIcon />}
            />
            <Chip
                icon={<DoneIcon />}
                label="ECMAScript 2017"
                clickable
                className={classes.chip}
                color="primary"
                deleteIcon={<DoneIcon />}
            />
            <Chip
                icon={<DoneIcon />}
                label="Apache Cordova"
                clickable
                className={classes.chip}
                color="primary"
                deleteIcon={<DoneIcon />}
            />
            <Chip
                icon={<DoneIcon />}
                label="MongoDB"
                clickable
                className={classes.chip}
                color="primary"
                deleteIcon={<DoneIcon />}
            />
            </div>
        </TabContainer>}
      </div>
    );
  }
}

SimpleTabs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleTabs);