import React, { Component } from 'react';
import './App.css';
import SimpleTabs from './SimpleTabs';


class App extends Component {

  render() {
    return (
      <div className="App">
        
          <p>
            Enix Technologies s.r.o.
          </p>
          <SimpleTabs/>
       
        
      </div>
    );
  }
}

export default App;
